var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "full"
    }
  }, [_c('Breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbs
    }
  }), _c('hr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "mt": "20px"
    }
  }), _c('c-text', {
    attrs: {
      "mt": "30px",
      "font-family": "Montserrat",
      "font-size": "24px",
      "font-weight": "700",
      "line-height": "36px"
    }
  }, [_vm._v(" Tambah Notifikasi ")]), _c('FormCreate', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "mt": "30px"
    },
    model: {
      value: _vm.notifikasi,
      callback: function callback($$v) {
        _vm.notifikasi = $$v;
      },
      expression: "notifikasi"
    }
  }), _c('c-stack', {
    attrs: {
      "mt": "30px",
      "is-inline": "",
      "spacing": "20px"
    }
  }, [_c('c-button', {
    attrs: {
      "rounded": "full",
      "h": "62px",
      "w": "289px",
      "as": "router-link",
      "to": {
        name: 'superadmin.notifikasi'
      }
    }
  }, [_vm._v(" Batal ")]), _c('c-button', {
    attrs: {
      "variant-color": "primary",
      "rounded": "full",
      "h": "62px",
      "w": "289px"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submit();
      }
    }
  }, [_vm._v(" Simpan ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }